import { useState } from "react";
import NavBar from '../Components/NavBar/NavBar'
import styles from './styles.module.css'
import About from './../Components/About/About'
import Projects from "../Components/Projects/Projects";
import Advantages from "../Components/Advantages/Advantages";
import '../../assets/css/iconfont.css'
import Footer from "../Components/Footer/Footer";
import Activities from "../Components/Activities/Activities";
import Contact from "../Components/Contact/Contact";
export function Home() {
    const [activeLink, setActiveLink] = useState('about');

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };
    return (
        <>
            <div className={styles.container}>
                <NavBar onLinkClick={handleLinkClick}></NavBar>
                <div className={styles.content}>
                    {activeLink === 'about' && <About onLinkClick={handleLinkClick} />}
                    {activeLink === 'activities' && <Activities onLinkClick={handleLinkClick} />}
                    {activeLink === 'projects' && <Projects onLinkClick={handleLinkClick} />}
                    {activeLink === 'advantages' && <Advantages onLinkClick={handleLinkClick} />}
                    {activeLink === 'contact' && <Contact onLinkClick={handleLinkClick} />}
                </div>
                <Footer />
            </div>
        </>
    )
}
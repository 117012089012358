
import styles from './footer.module.css'
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function Footer() {
    return (
        <>
            <footer className={styles.footer}>
                <Container>
                    <Row>
                        <Col className="text-center py-3">
                            <p>© {new Date().getFullYear()} Ronghui's Website. All rights reserved.</p>
                            <p>
                                Designed and Developed by Ronghui Zhong|{' '}
                                <a href="mailto:anxelswanz@outlook.com?subject=Hello%20Ronghui&body=I%20would%20like%20to%20talk%20about...">Contact Me</a> |{' '}
                                <a href="https://www.instagram.com/anxelswanz/" target="_blank" rel="noopener noreferrer">Instagram</a>
                            </p>
                            <p>All works and images are the property of Ronghui unless otherwise noted.</p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}
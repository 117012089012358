import styles from './styles.module.css'
import MyPhoto from '../../../assets/img2024_8/myphoto.png'
import '../../../assets/css/iconfont.css'
import Bling from '../../../assets/img2024_8/bling.png'
import CarouselX from '../Carousel/CarouselX';
import { useState, useEffect } from 'react'
import './box.css'
import { motion } from 'framer-motion';

function About({ onLinkClick }) {


    const contentStyle = {
        margin: '0 auto',
        height: '16rem',
        width: '24rem',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
        borderRadius: '1rem',
        opacity: '0.85'
    };

    const [isBox1Visible, setIsBox1Visible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            console.log("change");
            setIsBox1Visible(prev => !prev); // 每2秒切换一次状态
        }, 4000);
        return () => clearInterval(interval); // 清除定时器以防内存泄漏
    }, []);

    return (
        <>
            <motion.div
                initial={{ x: '-100vw', opacity: 0 }}  // 从左侧完全不可见的位置开始
                animate={{ x: 0, opacity: 1 }}          // 动画效果到达目标位置
                exit={{ x: '-100vw', opacity: 0 }}      // 退出动画效果
                transition={{ duration: 1.5, ease: 'easeOut' }}  // 延长持续时间并使用缓动效果
                className={styles.container}>
                <div className={styles.left}>

                    {isBox1Visible ? (
                        <div className={`box box1 ${isBox1Visible ? 'show' : ''}`}>Make Impossible Possible</div>
                    ) : (
                        <div className={`box box2 `}>Ronghui Zhong</div>
                    )}

                    <span className={styles.role}>Web Developer | Software Engineer</span>

                    <span className={styles.role}>& Explorer | Innovator</span>


                    <div className={styles.carousel}>
                        <div className={styles.recenthighlights}>Recent Highlights
                            <img style={{ width: '8%' }} src={Bling} />
                        </div>
                        <CarouselX />
                    </div>
                </div>
                <div className={styles.right}>
                    <img src={MyPhoto} className={styles.myphoto} />

                    <div
                        onClick={() => {
                            onLinkClick('advantages')
                        }}
                        className={styles.see}>
                        See what I can offer  <i className='iconfont icon-xiangyoujiantou'></i>

                    </div>

                </div>
            </motion.div >


        </>
    )
}

export default About;
import StudyUK from '../../../assets/img2024_8/British_Council_Cover.jpg';
import FreshCheck from '../../../assets/img2024_8/FreshCheck.png'
import Ifindpal from '../../../assets/img2024_8/Ifindpal.png'
import Dyson from '../../../assets/img2024_8/dyson4.jpg'
import { Carousel, Flex } from 'antd';

export default function CarouselX() {
    const contentStyle = {
        margin: '0 auto',
        height: '14rem',
        width: '20rem',
        textAlign: 'center',
        background: '#364d79',
        borderRadius: '1rem',
        opacity: '0.85',

    };

    const imgStyle = {
        maxHeight: '100%', // Ensure the image fits the container height
        maxWidth: '100%',  // Ensure the image fits the container width
        borderRadius: '1rem',
    };

    return (
        <>
            <Carousel autoplay>
                <div >
                    <img src={StudyUK} style={contentStyle} />
                </div>
                <div>
                    <img src={FreshCheck} style={contentStyle} />
                </div>
                <div>
                    <img src={Ifindpal} style={contentStyle} />
                </div>
                <div>
                    <img src={Dyson} style={contentStyle} />
                </div>
            </Carousel>
        </>
    );
}

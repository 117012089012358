
import { useEffect, useState, useRef } from 'react'
import styles from './styles.module.css'

export default function Project2023({ target }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
    const [key, setKey] = useState('');

    const itemRefs = {
        item1: useRef(null),
        item2: useRef(null),
        item3: useRef(null),
    };

    // 映射数字键到字符串键
    const targetKeyMap = {
        0: 'item1',
        1: 'item2',
        2: 'item3',
    };

    useEffect(() => {
        const targetKey = targetKeyMap[target];

        if (targetKey && itemRefs[targetKey] && itemRefs[targetKey].current) {
            console.log('target:', target);
            console.log(itemRefs[target]);
            itemRefs[targetKey].current.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Invalid target key or ref is not set correctly.');
        }
    }, target)

    return (
        <>
            <div className={styles.container}>
                <div ref={itemRefs.item1} className={styles.item}>
                    <div className={styles.title}>
                        ifindpal
                    </div>
                    <div className={styles.description}>
                        This platform is designed to help you find like-minded partners for various activities. Whether you're looking for someone to attend a concert with, a gym partner, or a fellow food enthusiast, iFindPal makes it easy to connect with people who share your interests.
                    </div>
                    <div className={styles.video}>
                        <iframe
                            width="460"
                            height="250"
                            src="https://www.youtube.com/embed/6BN-w6DrZwc?si=Bp2wKctFdod0kyTV"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
                <br />
                <br />
                <div ref={itemRefs.item2} className={styles.item}>
                    <div className={styles.title}>
                        Saleswift
                    </div>
                    <div className={styles.description}>
                        Second-hand furniture App - A mobile app designed to help local people sell their used furniture quickly.
                    </div>
                    <div className={styles.video}>
                        <iframe width="460"
                            height="250"
                            style={{ border: 0 }}
                            src="https://www.youtube.com/embed/1Ap16hVlQjY?si=P-Q-ab6tPDi8FjKg"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen></iframe>
                    </div>
                </div>



            </div>
        </>
    )
}

import { useEffect, useState, useRef } from 'react'
import styles from './styles.module.css'
import { Grid, Space } from 'antd';
import Zuoyou from '../../../../assets/img2024_8/Zuoyou.png'
import Dsuanpan from '../../../../assets/img2024_8/dsuanpan.png'
export default function Activities2023({ target }) {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
    const [key, setKey] = useState('');

    const itemRefs = {
        item1: useRef(null),
        item2: useRef(null),
        item3: useRef(null),
    };

    // 映射数字键到字符串键
    const targetKeyMap = {
        0: 'item1',
        1: 'item2',
        2: 'item3',
    };

    useEffect(() => {
        const targetKey = targetKeyMap[target];

        if (targetKey && itemRefs[targetKey] && itemRefs[targetKey].current) {
            console.log('target:', target);
            console.log(itemRefs[target]);
            itemRefs[targetKey].current.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Invalid target key or ref is not set correctly.');
        }
    }, target)

    return (
        <>
            <div className={styles.container}>
                <div ref={itemRefs.item1} className={styles.item}>
                    <Space direction={isMobile ? 'vertical' : 'horizontal'} >
                        <div>
                            <div className={styles.title}>
                                ZuoYou Technology
                            </div>
                            <div className={styles.description}>
                                <i className='iconfont icon-ditu'></i>CHANGSHA CHINA
                            </div>
                            <div className={styles.description}>
                                Back-End Software Engineer &#40; 2023.4 - 2023.8 &#41;
                            </div>
                            <div className={styles.description}>
                                Maintained and upgraded system for <span style={{ fontWeight: 'bold' }}>Misboot</span>,
                                a system based on the Spring Cloud microservce architecture that can achieve rapid development and operations of enterprise-level applications.
                            </div>
                            <div className={styles.description}>
                                Company Project Website:  <a href='https://www.misboot.com/' target='_blank'>Misboot</a>
                            </div>
                        </div>
                        <div >
                            <img src={Zuoyou} className={styles.pic} />
                        </div>
                    </Space>
                </div>

                <div ref={itemRefs.item2} className={styles.item}>
                    <Space direction={isMobile ? 'vertical' : 'horizontal'} >
                        <div>
                            <div className={styles.title}>
                                Dasuanpan Digital Technology Co., Ltd
                            </div>
                            <div className={styles.description}>
                                <i className='iconfont icon-ditu'></i>CHANGSHA CHINA
                            </div>
                            <div className={styles.description}>
                                Software Developer &#40; 2022.6 - 2023.3 &#41;
                            </div>
                            <div className={styles.description}>
                                Leverage and apply information technologies such as mobile internet, cloud computing, big data, and artificial intelligence to help businesses
                                easily respond to market changes and drive transformation. This approach comprehensively enhances organizational efficiency and competitiveness, reduces management costs, and mitigates financial risks.
                            </div>
                            <div className={styles.description}>
                                Company Project Website:  <a href='https://www.dsuanpan.com/' target='_blank'>dsuanpan</a>
                            </div>
                        </div>
                        <div >
                            <img src={Dsuanpan} className={styles.pic} />
                        </div>
                    </Space>
                </div>
            </div>
        </>
    )
}